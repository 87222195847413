import React from 'react'
import Layout from '../components/Layout'
import styled from "styled-components"
import { device } from '../components/Device'
import Image from '../images/scheduling.jpg'
import PinkFloydBackground from '../images/pf-background.png'
import SEO from '../components/SEO'

const Container = styled.div`
    margin: auto;
    width: 1024px;
    padding: 80px 0;
    @media (max-width: 1040px) {
        width: 768px;
    }

    @media (max-width: 787px) {
        width: 425px;
    }

    @media ${device.mobileL} {
        width: 100%
    }
`

const Title = styled.h1`
  font-family: Playfair Display;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 53px;
  letter-spacing: -0.01em;

  /* Core / 100 */

  color: #3E3E3E;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.01);
  @media ${device.mobileL} {
    margin-left: 20px;
    margin-right: 20px;
}
`

const Subtitle = styled.p`
/* P1 - Regular Text Body */

font-family: Google Sans;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
/* or 150% */
@media ${device.mobileL} {
  margin-left: 20px;
  margin-right: 20px;
}

/* Core / 100 */

color: #3E3E3E;

max-width: 448px;
margin-top: 32px;
`

const Background = styled.div`
  background-image: url(${PinkFloydBackground});
`

const FrameContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
`

const Seconds = styled.span`
  font-weight: 500;
  font-size: 16px;
`

const TransformDiv = styled.div`
    padding-right: 0;
    background: #FCEEE1;
    opacity: 1;
    width: 100%;
`

const ResponsiveImage = styled.img`
  width: 100%;
  // max-width: 900px;
  height: auto;
  // border-radius: 16px;
`

const openSchedulingWindow = () => {
  window.open("http://207.180.193.209:8077/", "_blank", `width=800, height=600, titlebar=no, scrollbars=yes, resizable=yes, location=no`)
}

class SchedulingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      count: 3
    }
  }

  tick() {
    if (this.state.count <= 0) {
      clearInterval(this.interval)
      openSchedulingWindow()
    } else {
      this.setState(state => ({
        count: state.count - 1
      }))
    }

  }

  componentDidMount() {
    this.interval = setInterval(() => this.tick(), 1000)
  }

  render() {
    return (<Layout>
      <Background>
        <Container>
        <TransformDiv>
          <div style={{"padding" : "16px"}}>
          <Title>Consulta e agendamento de casamento</Title>
          <Subtitle>É com imenso prazer que a serventia presta seus serviços neste momento marcante em suas vidas.</Subtitle>
          {this.state.count > 0 && (
            <Subtitle>Abrindo central de agendamento / consulta em <Seconds>{this.state.count}</Seconds>...</Subtitle>
          ) || (
              <Subtitle>Realize seu agendamento / consulta na página externa</Subtitle>
            )}
          <Subtitle>Caso o link não abra automaticamente <a href="#" onClick={() => openSchedulingWindow()}>clique aqui</a></Subtitle>
          </div>
          </TransformDiv>
          <FrameContainer>
            
            <ResponsiveImage src={Image} />
          </FrameContainer>
        </Container>
      </Background>
    </Layout>)
  }
}

export default SchedulingPage